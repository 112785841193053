import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import ContentWrapper from "../styles/contentWrapper"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Lightbox from "lightbox-react"
import "lightbox-react/style.css"

const StyledSection = styled(ContentWrapper)`
  .gallery {
    display: grid;
    grid-gap: 0.5em;

    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, 4fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 4fr);
    }
  }

  .gallery > * {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .gallery > :nth-child(6n + 3) {
    grid-column: span 1;
    grid-row: span 2;
  }

  .gallery > :nth-child(6n + 2),
  .gallery > :nth-child(6n + 4),
  .gallery > :nth-child(6n + 6) {
    grid-column: span 1;
    grid-row: span 2;
  }
`

const GalleryComponent = () => {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpen = (i) => () => {
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => () => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => () => {
    setSelectedImage((i + 1) % length)
  }
  return (
    <StaticQuery
      query={graphql`
        query allImgQuery {
          source: allFile(filter: { absolutePath: { regex: "/gallery/" } }) {
            edges {
              node {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    quality: 90
                  )
                  fluid {
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const images = data.source.edges

        const imageStyles = selectedImage && {
          styled: {
            maxWidth:
              images[selectedImage].node.childImageSharp.gatsbyImageData.width,
            height: window.innerHeight - 50,
            width: "auto",
            margin: "50px auto 0", // Used to center the image
          },
        }

        const selectedImageComponent = selectedImage && (
          <GatsbyImage
            alt="test"
            image={getImage(images[selectedImage].node)}
            style={imageStyles.styled}
          />
        )

        return (
          <StyledSection>
            <h3 id="portfolio" className="section-title">
              Portfolio
            </h3>
            <div className="gallery">
              {images.map((image, i) => (
                <GatsbyImage
                  onClick={handleOpen(i)}
                  key={i}
                  className="thumbnail"
                  image={getImage(image.node)}
                  alt="tedasfasdfst"
                />
              ))}
              {showLightbox && selectedImage !== null && (
                <Lightbox
                  mainSrc={selectedImageComponent}
                  onCloseRequest={handleClose}
                  nextSrc={selectedImageComponent}
                  prevSrc={selectedImageComponent}
                  onMovePrevRequest={handlePrevRequest(
                    selectedImage,
                    images.length
                  )}
                  onMoveNextRequest={handleNextRequest(
                    selectedImage,
                    images.length
                  )}
                />
              )}
            </div>
          </StyledSection>
        )
      }}
    />
  )
}
export default GalleryComponent
