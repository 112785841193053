import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

const StyledSection = styled.section`
  position: relative;
  background: ${({ theme }) => theme.colors.background};
  .title {
    margin-bottom: 1.5rem;
    position: absolute;
    color: white;
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    background: rgba(0, 0, 0, 0.5);
    padding: 4px 8px;
    transform: translate(-50%, -50%);
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }
  .image {
    @media (max-width: 1024px) {
      min-height: 500px;
    }
  }
`

const Hero = ({ content }) => {
  const { frontmatter } = content[0].node

  const images = withArtDirection(getImage(frontmatter.image), [
    {
      media: "(max-width: 1024px)",
      image: getImage(frontmatter.imageMobile),
    },
  ])

  return (
    <StyledSection id="hero">
      <GatsbyImage
        className="image"
        image={images}
        alt="Hero Image"
        loadin
        loading="lazy"
        layout
      />
      <h1 className="title">{frontmatter.title}</h1>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
