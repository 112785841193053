import React from "react"
import PropTypes from "prop-types"

import IconXing from "./xing"
import IconLinkedIn from "./linkedin"
import FacebookIcon from "./facebook"
import IconInstagram from "./instagram"
import IconExternal from "./external"
import IconMail from "./mail"

// Utility function to grab Icons by name
const Icon = ({ name, color }) => {
  switch (name.toLowerCase()) {
    case "xing":
      return <IconXing color={color} />
    case "linkedin":
      return <IconLinkedIn color={color} />
    case "facebook":
      return <FacebookIcon color={color} />
    case "instagram":
      return <IconInstagram color={color} />
    case "external":
      return <IconExternal color={color} />
    case "mail":
      return <IconMail color={color} />
    default:
      return null
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default Icon
